(function() {
  let isCurrentSizeMobile;
  const WIDE_WIDTH = 1000;
  const MAP_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
  const MAP_URL_WIDE = `https://www.google.com/maps/embed/v1/place?center=52.9947848,18.6035731&q=karolina%20cie%C5%9Blik%2C%20toru%C5%84%20pozna%C5%84ska%2042&key=${MAP_API_KEY}`;
  const MAP_URL_MOBILE = `https://www.google.com/maps/embed/v1/place?q=karolina%20cie%C5%9Blik%2C%20toru%C5%84%20pozna%C5%84ska%2042&key=${MAP_API_KEY}`;

  if (IntersectionObserver) {
    // list slide in
    const listItemElements = document.querySelectorAll('.main-content-boxed-list li');
    const slideInObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }

        entry.target.classList.remove('slide-in--out');
        entry.target.classList.add('slide-in--in');

        observer.unobserve(entry.target);
      });
    });

    listItemElements.forEach(elem => {
      elem.classList.add('slide-in--out');
      slideInObserver.observe(elem);
    });
  }

  if (ResizeObserver) {
    // map
    const mapContainerElement = document.querySelector('.contact-container');
    const mapElement = document.querySelector('.contact-map');

    function isMobile(width) {
      return width < WIDE_WIDTH;
    }

    function viewModeChanged(width) {
      if (isCurrentSizeMobile && isMobile(width)) {
        return false;
      }

      if (!isCurrentSizeMobile && !isMobile(width)) {
        return false;
      }

      isCurrentSizeMobile = isMobile(width);

      return true;
    }

    function getMapUrl(width) {
      return isMobile(width) ? MAP_URL_MOBILE : MAP_URL_WIDE;
    }

    const resizer = new ResizeObserver(function(entries) {
      const width = entries[0].contentRect.width;

      if (viewModeChanged(width)) {
        mapElement.src = getMapUrl(width);
      }
    });

    resizer.observe(mapContainerElement)

    isCurrentSizeMobile = isMobile(mapContainerElement.clientWidth)

    mapElement.src = getMapUrl(mapContainerElement.clientWidth);
  }

  // set copyright year
  const copyrightYearElement = document.getElementById('footer-copyright-year')
  copyrightYearElement.textContent = new Date().getFullYear();
})();

